import React from "react";
import {useTranslation} from 'react-i18next';

import "./CustomersStyles.scss";
import {CardItem} from "models";
import BioDiesel from "assets/customers/biodiesel.jpg";
import HVO from "assets/customers/hvo.jpg";
import ConstructionCompanies from "assets/customers/construction-companies.jpg";
import PaintProduction from "assets/customers/paint-production.jpg";
import CosmeticCompanies from "assets/customers/cosmetic-companies.jpg";
import ChemicalAndIndustrialCompanies from "assets/customers/chemical-and-industrial-companies.jpg";
import Intermediaries from "assets/customers/intermediaries.jpg";

export default function Customers() {
    const {t} = useTranslation();
    const customers: CardItem[] = [
        {
            name: 'customers.cardName',
            description: 'customers.cardDescription',
            img: BioDiesel
        },
        {
            name: 'customers.cardName1',
            description: 'customers.cardDescription1',
            img: HVO
        },
        {
            name: 'customers.cardName2',
            description: 'customers.cardDescription2',
            img: ConstructionCompanies
        },
        {
            name: 'customers.cardName3',
            description: 'customers.cardDescription3',
            img: PaintProduction
        },
        {
            name: 'customers.cardName4',
            description: 'customers.cardDescription4',
            img: CosmeticCompanies
        },
        {
            name: 'customers.cardName5',
            description: 'customers.cardDescription5',
            img: ChemicalAndIndustrialCompanies
        },
        {
            name: 'customers.cardName6',
            description: 'customers.cardDescription6',
            img: Intermediaries
        },
    ];


    return (
        <div className="customersPage">
            <div className="titleContainer">
                <h1>{t('customers.title')}</h1>
            </div>

            <div className="customersPageHeader">
                <p className="customersPageDescription">
                    {t('customers.subtitle')}
                </p>
                <p className="customersPageDescription">
                    {t('customers.subtitle1')}
                </p>
            </div>

            <div className="customersList">
                {
                    customers.map((customer, index) => (
                        <div className="customer" key={index}>
                            <div className="customerImgContainer">
                                <img src={customer.img} alt={customer.name}/>
                            </div>
                            <div className="customerInfo">
                                <h2 className="customerName">{t(customer.name)}</h2>
                                <p className="customerDescription">{t(customer.description)}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}