import {useTranslation} from 'react-i18next';

import "./ProcessStyles.scss";
import ProcessLifecycle from "assets/process/process.png"
import ProcessLifecycleRO from "assets/process/processRO.png"


export default function Process() {
    const {t} = useTranslation();
    const {i18n} = useTranslation();

    const imageSrc = i18n.language === 'ro' ? ProcessLifecycleRO : ProcessLifecycle;

    return (
        <div className="processPage">
            <div className="titleContainer">
                <h1>{t('process.title')}</h1>
            </div>

            <div className="processLifecycleImage">
                <img src={imageSrc} alt="Process Lifecycle"/>
            </div>

            <div className="processPageContent">
                <p>
                    {t('process.subtitle')}
                </p>

                <p>
                    {t('process.subtitle1')}
                </p>

                <p>
                    {t('process.subtitle2')}
                </p>

                <p>
                    {t('process.subtitle3')}
                </p>

                <p>
                    {t('process.subtitle4')}
                </p>
            </div>
        </div>
    );
}