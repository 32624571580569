import React from "react";

import "./SupplieresStyles.scss";
import {CardItem} from "models";
import PrivateIndividuals from "assets/suppliers/private-individuals.jpg";
import RestaurantBusiness from "assets/suppliers/restaurant-business.jpg";
import WasteOilCollectors from "assets/suppliers/waste-oil-collectors.webp";
import CollectionPoints from "assets/suppliers/collection-points.jpg";
import FoodCompanies from "assets/suppliers/food-companies.jpg";
import OilRefiners from "assets/suppliers/oil-refiners.jpg";
import Traders from "assets/suppliers/traders.jpg";

import { useTranslation } from 'react-i18next';

export default function Suppliers() {
    const { t } = useTranslation();
    const suppliers: CardItem[] = [
        {
            name: 'suppliers.cardtitle',
            description: 'suppliers.carddescription',
            img: PrivateIndividuals
        },
        {
            name: 'suppliers.cardtitle1',
            description: 'suppliers.carddescription1',
            img: RestaurantBusiness
        },
        {
            name: 'suppliers.cardtitle2',
            description: 'suppliers.carddescription2',
            img: WasteOilCollectors
        },
        {
            name: 'suppliers.cardtitle3',
            description: 'suppliers.carddescription3',
            img: CollectionPoints
        },
        {
            name: 'suppliers.cardtitle4',
            description: 'suppliers.carddescription4',
            img: FoodCompanies
        },
        {
            name: 'suppliers.cardtitle5',
            description: 'suppliers.carddescription5',
            img: OilRefiners
        },
        {
            name: 'suppliers.cardtitle6',
            description: 'suppliers.carddescription6',
            img: Traders
        },
    ];

    return (
        <div className="suppliersPage">
            <div className="titleContainer">
            <h1>{t('suppliers.title')}</h1>
            </div>
            <div className="suppliersPageHeader">
                <p className="suppliersPageDescription">
                {t('suppliers.subtitle')}
                </p>
            </div>

            <div className="suppliersList">
                {
                    suppliers.map((supplier, index) => (
                        <div className="supplier" key={index}>
                            <div className="supplierImgContainer">
                                <img src={supplier.img} alt={supplier.name}/>
                            </div>
                            <div className="supplierInfo">
                                <h2 className="supplierName">{t(supplier.name)}</h2>
                                <p className="supplierDescription">{t(supplier.description)}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}