import React, {useState, FormEvent} from "react";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import emailjs from "emailjs-com";
import { useTranslation } from 'react-i18next';

import "./ContactStyles.scss";

interface FormValues {
    name: string;
    email: string;
    phone: string;
    message: string;
}

interface FormErrors {
    name?: string;
    email?: string;
    phone?: string;
    message?: string;
}

export default function Contact() {
    
    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [isChecked, setIsChecked] = useState(false);

    const validateForm = (): FormErrors => {
        let errors: FormErrors = {};

        if (!formValues.name) {
            errors.name = "Name is required";
        }

        if (!formValues.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
            errors.email = "Invalid email address";
        }

        if (!formValues.phone.match(/^\d+$/)) {
            errors.phone = "Phone number must be numeric";
        }

        if (!formValues.message) {
            errors.message = "Message is required";
        }

        return errors;
    };

    const sendEmail = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const form = e.target as HTMLFormElement;

        emailjs.sendForm('service_c5iwqy8', 'template_ufus27a', form, 'Fie848GL4iEBlGlYv')
            .then(() => {
                const errors = validateForm();

                if (!Object.keys(errors).length) {
                    setFormValues({name: '', email: '', phone: '', message: ''});
                    setIsChecked(false);
                } else {
                    setFormErrors(errors);
                }
            }, (error) => {
                console.log(error.text);
            });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;

        if (name === "phone") {
            // Allow only numeric input for phone
            if (value && !/^\d+/.test(value)) {
                event.preventDefault();
                return;
            }
        }

        if (name === "name") {
            // Disallow numeric input for name
            if (/\d/.test(value)) {
                event.preventDefault();
                return;
            }
        }

        setFormValues({...formValues, [name]: value});
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };
    const { t } = useTranslation();
    return (
        <div className="contactPage">
            <div className="titleContainer">
                <h1>{t('contact.title')}</h1>
            </div>
            <div className="contactElements">
                <div className="contactInfoContainer">
                    <h1 className="contactInfoTitle">{t('contact.contactinfo')}</h1>
                    <div className="contactInfoItemsList">
                        <div className="contactInfoItem">
                            <HomeIcon className="icon"/>
                            <div>
                                <h2>Sat Chistag com Astileu nr.206 Bihor Romania</h2>
                                <div className="description">{t('contact.whereweare')}</div>
                            </div>
                        </div>
                        <div className="contactInfoItem">
                            <PhoneInTalkIcon className="icon"/>
                            <div>
                                <h2>+40 784 888 881</h2>
                                <div className="description">Alexandru Cormos</div>
                            </div>
                        </div>
                        <div className="contactInfoItem">
                            <EmailIcon className="icon"/>
                            <h2>office@envero.ro</h2>
                        </div>
                    </div>
                </div>

                <div className="contactFormContainer">
                    <h1 className="contactInfoTitle">{t('contact.contactform')}</h1>
                    <form onSubmit={sendEmail}>
                        <TextField
                            required
                            fullWidth
                            id="name"
                            label={t('contact.name')}
                            name="name"
                            value={formValues.name}
                            onChange={handleInputChange}
                            error={Boolean(formErrors.name)}
                            helperText={formErrors.name || ''}
                        />

                        <TextField
                            required
                            fullWidth
                            id="email"
                            label={t('contact.email')}
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            error={Boolean(formErrors.email)}
                            helperText={formErrors.email || ''}
                        />

                        <TextField
                            required
                            fullWidth
                            id="phone"
                            label={t('contact.phone')}
                            name="phone"
                            value={formValues.phone}
                            onChange={handleInputChange}
                            error={Boolean(formErrors.phone)}
                            helperText={formErrors.phone || ''}
                        />

                        <TextField
                            required
                            fullWidth
                            name="message"
                            label={t('contact.message')}
                            type="text"
                            id="message"
                            multiline
                            rows={4}
                            value={formValues.message}
                            onChange={handleInputChange}
                            error={Boolean(formErrors.message)}
                            helperText={formErrors.message || ''}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    name="acceptPolicy"
                                    color="primary"
                                />
                            }
                            label={t('contact.iaccept')}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!isChecked}
                        >
                           {t('contact.send')}
                        </Button>
                    </form>
                </div>
            </div>

            <div className="locationMapContainer">
                <h1 className="contactInfoTitle">{t('contact.location')}</h1>
                <div className="locationMap">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d11823.43795654694!2d22.345118752429055!3d47.03476699128516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDAyJzAyLjQiTiAyMsKwMjEnMTguMSJF!5e1!3m2!1sen!2sro!4v1699801858204!5m2!1sen!2sro"
                        height="450"
                        loading="lazy"
                        style={{border: 0}}
                    ></iframe>
                </div>
            </div>
        </div>
    );
}