import React, {MouseEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import InventoryIcon from '@mui/icons-material/Inventory';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HandymanIcon from '@mui/icons-material/Handyman';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {Avatar, Tooltip} from "@mui/material";
import {useTranslation} from 'react-i18next';

import './HeaderStyles.scss';
import header_logo from "assets/header_logo.png";
import {Language, MenuItemElement} from 'models';

export default function Header() {
    const {t, i18n} = useTranslation();

    const languages: Language[] = [
        {
            label: "English",
            icon: 'gb.svg',
            value: "en",
            isDefault: false
        },
        {
            label: "Romanian",
            icon: 'ro.svg',
            value: "ro",
            isDefault: true
        }
    ];

    const defaultLanguage: Language = languages.find(x => x.isDefault)!;
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    const menuItems: MenuItemElement[] = [
        {
            name: 'home.home',
            icon: HomeIcon,
            route: '/'
        },
        {
            name: 'aboutUs.title',
            icon: PeopleIcon,
            route: 'about-us'
        },
        {
            name: 'products.title',
            icon: InventoryIcon,
            route: 'products'
        },
        {
            name: 'oilmills.title',
            icon: OilBarrelIcon,
            route: 'oil-mills'
        },
        {
            name: 'suppliers.title',
            icon: LocalShippingIcon,
            route: 'suppliers'
        },
        {
            name: 'process.title',
            icon: HandymanIcon,
            route: 'process'
        },
        {
            name: 'customers.title',
            icon: FolderSharedIcon,
            route: 'customers'
        },
        {
            name: 'certificates.title',
            icon: ChecklistIcon,
            route: 'certificates'
        },
        {
            name: 'contact.title',
            icon: ContactSupportIcon,
            route: 'contact'
        }
    ];

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null);

    const navigate = useNavigate();

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleMenuClick = (route: string) => {
        navigate(route);
        handleCloseNavMenu();
    }

    const handleOpenLanguageSelectorMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElLang(event.currentTarget);
    };

    const handleCloseLanguageSelectorMenu = () => {
        setAnchorElLang(null);
    };

    const handleLanguageChange = (language: Language) => {
        handleCloseLanguageSelectorMenu();

        setSelectedLanguage(language);

        i18n.changeLanguage(language.value)
    }

    const handleLogoClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const getLanguageFlag = (languageIcon: string) => {
        return require(`assets/languages/${languageIcon}`)
    }

    return (
        <AppBar position="sticky" className="appBar">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        onClick={handleLogoClick}
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                        }}
                    >
                        <Container component={'span'} className="imageContainer">
                            <img src={header_logo} alt="Logo" onClick={handleLogoClick}/>
                        </Container>
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon style={{color: 'white'}}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar-mobile"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {menuItems.map((val, index) => (
                                <MenuItem key={index} onClick={() => {
                                    handleMenuClick(val.route)
                                }}>
                                    <Typography textAlign="center">{t(val.name)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Typography
                        noWrap
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                        }}
                    >
                        <Container component={'span'} className="imageContainer">
                            <img src={header_logo} alt="Logo" onClick={handleLogoClick}/>
                        </Container>
                    </Typography>

                    <Box id="menu-appbar" sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {menuItems.map((val, index) => (
                            <Button
                                key={index}
                                onClick={() => {
                                    handleMenuClick(val.route)
                                }}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {t(val.name)}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{flexGrow: 0}} className="languagePicker">
                        <Tooltip title="Select language">
                            <IconButton onClick={handleOpenLanguageSelectorMenu} sx={{p: 0}}>
                                <Avatar alt={`${selectedLanguage.label} Flag`}
                                        src={getLanguageFlag(selectedLanguage.icon)}/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar-language-selector"
                            anchorEl={anchorElLang}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElLang)}
                            onClose={handleCloseLanguageSelectorMenu}
                        >
                            {languages.map((language, index) => (
                                <MenuItem key={index} onClick={() => handleLanguageChange(language)}>
                                    <img src={getLanguageFlag(language.icon)} className="languageFlag"/>
                                    <Typography textAlign="center">{language.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}