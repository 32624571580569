import React from "react";
import {ThemeProvider} from "@mui/material";
import {Route, Routes} from "react-router-dom";

import "./App.scss";
import {THEME} from "./theme";
import Header from "pages/Header/Header";
import Footer from "pages/Footer/Footer";
import Home from "pages/Home/Home"
import Products from "pages/Products/Products";
import AboutUs from "pages/AboutUs/AboutUs";
import OilMills from "pages/OilMills/OilMills";
import Suppliers from "pages/Suppliers/Supplieres";
import Process from "pages/Process/Process";
import Customers from "pages/Customers/Customers";
import Certificates from "pages/Certificates/Certificates";
import Contact from "pages/Contact/Contact";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

export default function App() {
    return (
        <ThemeProvider theme={THEME}>
            <ScrollToTop/>

            <Header/>
            <div className="content">
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/about-us" element={<AboutUs/>}></Route>
                    <Route path="/products" element={<Products/>}></Route>
                    <Route path="/oil-mills" element={<OilMills/>}></Route>
                    <Route path="/suppliers" element={<Suppliers/>}></Route>
                    <Route path="/process" element={<Process/>}></Route>
                    <Route path="/customers" element={<Customers/>}></Route>
                    <Route path="/certificates" element={<Certificates/>}></Route>
                    <Route path="/contact" element={<Contact/>}></Route>
                </Routes>
            </div>

            <Footer/>
        </ThemeProvider>
    );
}
