import {useTranslation} from 'react-i18next';

import "./CertificatesStyles.scss";
import iscc from "assets/certificates/iscc.jpg";

export default function Certificates() {
    const {t} = useTranslation();

    return (
        <div className="certificatesPageContainer">
            <div className="titleContainer">
                <h1>{t('certificates.title')}</h1>
            </div>

            <div className="certificatesPageContent">
                <section>
                    <h2>{t('certificates.subtitle')}</h2>
                    <hr/>
                    <p>{t('certificates.subdescription')}</p>
                </section>

                <section>
                    <h2>{t('certificates.subtitle1')}</h2>
                    <hr/>
                    <p>{t('certificates.subdescription1')}</p>
                </section>

                <section>
                    <h2>{t('certificates.subtitle2')}</h2>
                    <hr/>
                    <p>{t('certificates.subdescription2')}</p>
                </section>
            </div>

            <div className="certificatesContainer">
                <a href="https://drive.google.com/file/d/14wrJMWuMCO4ubaqVDd5nR3gsYWdmQ_DS/view?usp=drive_link"
                   rel="noopener noreferrer" target="_blank">
                    <img src={iscc}/>
                </a>
            </div>
        </div>
    );
}