import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {useTranslation} from 'react-i18next';

import "./OilMillsStyles.scss";
import oil_mills from "assets/oil-mills/oil-mills.jpeg"

export default function OilMills() {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate('/contact');
    }

    const {t} = useTranslation();

    return (
        <div className="oilMillsPage">
            <div className="titleContainer">
                <h1>{t('oilmills.title')}</h1>
            </div>

            <div className="oilMillsContent">
                <div className="imageContainer">
                    <img src={oil_mills} alt="Oil Mills"/>
                </div>

                <div className="contentContainer">
                    <p>
                        {t('oilmills.subtitle')}
                    </p>

                    <p>
                        {t('oilmills.subtitle1')}
                    </p>

                    <Button onClick={handleContact} variant="contained" className="quoteButton">
                        {t('oilmills.getQuote')}
                    </Button>
                </div>
            </div>
        </div>
    );
}