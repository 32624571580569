import React from "react";
import {useNavigate} from "react-router-dom";
import {Carousel} from "react-responsive-carousel";
import {Button} from "@mui/material";
import InventoryIcon from '@mui/icons-material/Inventory';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ChecklistIcon from '@mui/icons-material/Checklist';
import {useTranslation} from 'react-i18next';

import "./HomeStyles.scss";
import sunflowers from "assets/home/sunflowers.jpg";
import sunflowersField from "assets/home/sunflowers-field.jpg";

export default function Home() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleSectionInfoNavigation = (route: string) => {
        navigate(`/${route}`);
    }

    return (
        <div className="homePage">
            <div>
                <Carousel
                    autoPlay
                    infiniteLoop
                    interval={5000}
                    transitionTime={1000}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    className="homeCarousel"
                >
                    <div>
                        <img src={sunflowers}/>
                    </div>

                    <div>
                        <img src={sunflowersField}/>
                    </div>
                </Carousel>

                <div className="homePageContent">
                    <h1 className="homePageTitle">{t('home.title')}</h1>

                    <div className="homePageDescription">
                        <p>{t('home.subtitle')}</p>
                        <p>{t('home.subtitle2')}</p>
                    </div>

                    <div className="homePageInfo">
                        <div className="homePageInfoSection">
                            <InventoryIcon className="sectionIcon"/>

                            <h3 className="sectionTitle">{t('home.descriptiontitle')}</h3>

                            <div className="sectionDescription">
                                {t('home.description')}
                            </div>

                            <Button
                                variant="contained"
                                onClick={() => handleSectionInfoNavigation('products')}
                            >
                                <span>{t('home.descriptionButton')}</span>
                            </Button>
                        </div>
                        <div className="homePageInfoSection">
                            <FolderSharedIcon className="sectionIcon"/>

                            <h3 className="sectionTitle">{t('home.descriptiontitle1')}</h3>

                            <div className="sectionDescription">
                                {t('home.description1')}
                            </div>

                            <Button
                                variant="contained"
                                onClick={() => handleSectionInfoNavigation('customers')}
                            >
                                <span><span>{t('home.descriptionButton')}</span></span>
                            </Button>
                        </div>
                        <div className="homePageInfoSection">
                            <ChecklistIcon className="sectionIcon"/>

                            <h3 className="sectionTitle">{t('home.descriptiontitle2')}</h3>

                            <div className="sectionDescription">
                                {t('home.description2')}
                            </div>

                            <Button
                                variant="contained"
                                onClick={() => handleSectionInfoNavigation('certificates')}
                            >
                                <span><span>{t('home.descriptionButton')}</span></span>
                            </Button>
                        </div>
                    </div>

                    <div className="homePageLocation">
                        <div className="locationInfo">
                            <p>{t('home.locationInfo')}</p>
                            <p>{t('home.locationInfo2')}</p>
                        </div>

                        <div className="locationMap">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d11823.43795654694!2d22.345118752429055!3d47.03476699128516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDAyJzAyLjQiTiAyMsKwMjEnMTguMSJF!5e1!3m2!1sen!2sro!4v1699801858204!5m2!1sen!2sro"
                                height="450"
                                loading="lazy"
                                style={{border: 0}}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}