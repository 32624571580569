import React from 'react';
import {Link} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import {NavLink} from "react-router-dom";
import {useTranslation} from 'react-i18next';

import './FooterStyles.scss';
import footer_logo from "assets/footer_logo.png";
import {MenuItemElement} from "models";

export default function Footer() {
    const {t} = useTranslation();

    const menuItems: MenuItemElement[] = [
        {
            name: 'home.home',
            route: '/'
        },
        {
            name: 'aboutUs.title',
            route: 'about-us'
        },
        {
            name: 'products.title',
            route: 'products'
        },
        {
            name: 'oilmills.title',
            route: 'oil-mills'
        },
        {
            name: 'suppliers.title',
            route: 'suppliers'
        },
        {
            name: 'process.title',
            route: 'process'
        },
        {
            name: 'customers.title',
            route: 'customers'
        },
        {
            name: 'certificates.title',
            route: 'certificates'
        },
        {
            name: 'contact.title',
            route: 'contact'
        }
    ];

    return (
        <div className="footer">
            <div className='footerLogo'>
                <img className='logo' alt="Logo" src={footer_logo}/>
            </div>

            <div className='footerMenu'>
                {
                    menuItems.map((val, index) => {
                        return (
                            <div key={index} className="menuItemElement">
                                <NavLink
                                    to={val.route}>
                                    <span>{t(val.name)}</span>
                                </NavLink>
                            </div>
                        );
                    })
                }
            </div>

            <div className='footerMail'>
                <Link href="mailto:office@envero.ro" className="link">
                    <EmailIcon/>
                    <div>office@envero.ro</div>
                </Link>
            </div>

            <div className='footerAllRights'>
                <span className='allRightsContent'>
                    {`© ${new Date().getFullYear()} All rights reserved by S.C Envero Energia Verde S.R.L. `}
                </span>
            </div>
        </div>
    );
};
