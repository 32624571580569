import React from "react";

import "./ProductsStyles.scss";
import UsedCookingOil from "assets/products/used-cooking-oil.jpeg";
import ExpiredOil from "assets/products/expired-oil.jpeg";
import FattyAcids from "assets/products/fatty-acids.jpeg";
import OilTanks from "assets/products/oil-tanks.jpeg";
import PalmOilWaste from "assets/products/palm-oil-waste.jpeg";
import TechnicalOil from "assets/products/technical-oils.jpeg";
import {CardItem} from "models";
import {useTranslation} from 'react-i18next';

export default function Products() {
    const {t} = useTranslation();
    const products: CardItem[] = [
        {
            name: 'products.cardName',
            description: 'products.cardDescription',
            img: UsedCookingOil
        },
        {
            name: 'products.cardName1',
            description: 'products.cardDescription1',
            img: OilTanks
        },
        {
            name: 'products.cardName2',
            description: 'products.cardDescription2',
            img: ExpiredOil
        },
        {
            name: 'products.cardName3',
            description: 'products.cardDescription3',
            img: TechnicalOil
        },
        {
            name: 'products.cardName4',
            description: 'products.cardDescription4',
            img: FattyAcids
        },
        {
            name: 'products.cardName5',
            description: 'products.cardDescription5',
            img: PalmOilWaste
        },
    ];

    return (
        <div className="productsPage">
            <div className="titleContainer">
                <h1>{t('products.title')}</h1>
            </div>

            <div className="productsPageHeader">
                <p className="productsPageDescription">{t('products.subtitle')}</p>
            </div>

            <div className="productsList">
                {
                    products.map((product, index) => (
                        <div className="product" key={index}>
                            <div className="productImgContainer">
                                <img src={product.img} alt={product.name} className="productImg"/>
                            </div>
                            <div className="productInfo">
                                <h2 className="productName">{t(product.name)}</h2>
                                <p className="productDescription">{t(product.description)}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}