import React from "react";
import {useTranslation} from 'react-i18next';

import "./AboutUsStyles.scss"
import trucks from "assets/about-us/trucks.jpg";
import scientist from "assets/about-us/scientist.jpg";
import tanks from "assets/about-us/tanks.jpg";

export default function AboutUs() {
    const {t} = useTranslation();
    return (
        <div className="aboutUsPage">
            <div className="titleContainer">
                <h1>{t('aboutUs.title')}</h1>
            </div>

            <div className="card">
                <div className="imageContainer">
                    <img src={scientist} alt="Scientist"/>
                </div>

                <div className="section">
                    <h2>{t('aboutUs.titleCard')}</h2>

                    <div className="sectionDescription">
                        <p>
                            {t('aboutUs.descriptionCard')}
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="imageContainer">
                    <img src={tanks} alt="Tanks"/>
                </div>

                <div className="section">
                    <h2>{t('aboutUs.titleCard1')}</h2>

                    <div className="sectionDescription">
                        <p>
                            {t('aboutUs.descriptionCard1')}
                        </p>

                        <p>
                            {t('aboutUs.descriptionCard11')}
                        </p>

                        <p>
                            {t('aboutUs.descriptionCard12')}
                        </p>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="imageContainer">
                    <img src={trucks} alt="Envero trucks"/>
                </div>

                <div className="section">
                    <h2>{t('aboutUs.titleCard2')}</h2>

                    <div className="sectionDescription">
                        <p>
                            {t('aboutUs.descriptionCard2')}
                        </p>

                        <p>
                            {t('aboutUs.descriptionCard21')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}